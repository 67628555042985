.project-main{
  padding: 0 10px;
  a{
    &:hover .element-description{
      opacity: .9;
      color: #222;
    }
  }
  &:hover img{
    transform: scale(1.1);
  }
  .subtitle{
    display: none;
  }
  .element-date{
    display: none;
  }
  .element-photo{
    max-width: 150px;
    margin: 20px auto;
    img{
      width: 100%;
      height: 100%;
      transition: transform .2s;
    }
  }
  .element-title{
    font-size: 20px;
  }
  .element-description{
    font-size: 85%;
    opacity: 0.8;
    font-style: italic;
    padding: 5px;
  }
}