.layout-main{
  a{
    color: #555;
  }

  overflow: hidden;
  position: relative;
  .ribbon{
    transform: rotate(45deg);
    // position: relative;
    position: absolute;
    width: 150px;
    background: #2b940c;
    letter-spacing: 1px;
    color: #fff;
    font-size: .8rem;
    font-weight: 600;
    border-radius: 3px 0 0 3px;
    // float: right;
    padding: 2px 10px;
    z-index: 999;
    text-align: center;
  }
}

.element-title{
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  line-height: 1.3;
}