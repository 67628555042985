.thought2-main{
  a{
    &:hover .element-date{
      opacity: .9;
      color: #222;
    }
  }
  &:hover img{
    transform: scale(1.1);
  }
  .element-subtitle{
    display: none;
  }
  .element-description{
    display: none;
  }
  .element-photo{
    height: 100px;
    margin: 0 auto 10px auto;
    overflow: hidden;
    border-radius: 7px;
    img{
      width: 100%;
      height: 100%;
      transition: transform .2s;
      object-fit: cover;
    }
    @media (min-width: 992px){
      height: 120px;
    }
    @media (min-width: 1200px){
      height: 150px;
    }
  }
  .element-title{
    font-size: 1.1rem;
  }
  .element-date{
    font-size: 0.8rem;
    opacity: 0.8;
    margin-top: 5px;
  }

  .ribbon{
    top: 18px;
    right: -46px;
  }
}