// general
@import 'layout-general'; // _layout_general.scss

// project
@import 'layout-project'; // _layout_project.scss

// reading
@import 'layout-reading'; // _layout_reading.scss

// tutorial
@import 'layout-tutorial'; // _layout_tutorial.scss

// thought
@import 'layout-thought2';
